<template>
  <section class="">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="product === null">
      <h4 class="alert-heading">
        {{ $t('trip.shop.alert.fetching_data_product') }}
      </h4>
      <div class="alert-body">
        {{ $t('trip.shop.alert.no_items') }}
      </div>
    </b-alert>
    <!--      Content-->
    <b-card v-if="product">
      <b-card-body>
        <b-overlay :show="productLoading" spinner-medium>
          <b-row class="my-2">
            <!-- Left: Product Image Container -->
            <b-col cols="4" class="d-flex align-items-center justify-content-center mb-2 mb-md-0">
              <div class="d-flex align-items-center justify-content-center">
                <div class="product-img">
                  <b-img
                    :src="product.mainVariant.files.length ? product.mainVariant.files[0].fileUrls[300] : require('@/assets/images/logo/logo.png')"
                    fluid
                  />
                </div>
              </div>
            </b-col>
            <!-- Right: Product Details -->
            <b-col cols="7">
              <!-- Product Description -->
              <div class="mb-3">
                <h1 class="mb-2 mr-1 font-weight-bold">{{ product.name | trans }}</h1>
                <b-card-text class="mb-1">
                  <font-awesome-icon icon="users" class="mr-50" style="width: 25px" /><span>{{ product.transfer.passengers }} {{ (product.transfer.passengers > 1) ? $t('trip.shop.passengers') : $t('trip.shop.passenger') }}</span>
                </b-card-text>
                <b-card-text class="mb-1">
                  <font-awesome-icon icon="suitcase" class="mr-50" style="width: 25px" /><span>{{ product.transfer.luggage }} {{ (product.transfer.luggage > 1) ? $t('trip.shop.suitcases') : $t('trip.shop.suitcase') }}</span>
                </b-card-text>
                <b-card-text class="mb-1">
                  <font-awesome-icon icon="bag-shopping" class="mr-50" style="width: 25px" /><span>{{ product.transfer.bags }} {{ (product.transfer.bags > 1) ? $t('trip.shop.light_bags') : $t('trip.shop.light_bag') }}</span>
                </b-card-text>
                <b-card-text v-if="regularFlight" class="mb-1">
                  <font-awesome-icon icon="clock" class="mr-50" style="width: 25px" /><span>{{ product.flyingTime }} {{ $t('common.min') }}</span>
                </b-card-text>
                <b-card-text v-if="regularFlight" class="mb-1">
                  <font-awesome-icon icon="helicopter" class="mr-50" style="width: 25px" /><span>{{ product.transfer.engineType }}</span>
                </b-card-text>
                <b-card-title v-if="product.mainVariant && product.mainVariant.variantAttributes.length">
                  {{ $tc('service.additional_informations.characteristic', product.mainVariant.variantAttributes.length) }}
                  :
                </b-card-title>
                <ul v-if="product.mainVariant && product.mainVariant.variantAttributes.length">
                  <li v-for="attribute in product.mainVariant.variantAttributes" :key="attribute.id">
                    {{ attribute.attribute.name | trans }} :
                    <span v-if="attribute.inputValues">{{ attribute.inputValues | trans }}</span>
                    <span v-if="attribute.selectValue">{{ attribute.selectValue.name | trans }}</span>
                    <ul v-if="attribute.choiceValues">
                      <li v-for="choice in attribute.choiceValues" :key="choice.id" class="my-1">
                        <b-link :href="choice.iconFileUrl" target="_blank">
                          <img v-if="choice.iconFileUrl" height="40px" :src="choice.iconFileUrl" :alt="choice.name">
                        </b-link>
                        {{ choice.name | trans }}
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <!-- Flight Description -->
              <div v-if="regularFlight">
                <h2 class="mb-2 mr-1 font-weight-bold">{{ $t('trip.shop.flight') }}</h2>
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">{{ $t('trip.shop.departure') }} : {{ product.departureName }}</span>
                </b-card-text>
                <b-card-text v-if="product.departureDepositZones.length > 0" class="mb-2">
                  <span class="font-weight-bold">{{ $t('trip.shop.deposit_zones') }}</span>
                  <ul><li v-for="zone in product.departureDepositZones" :key="zone.id">{{ zone.address }}</li></ul>
                </b-card-text>
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">{{ $t('trip.shop.arrival') }} : {{ product.arrivalName }}</span>
                </b-card-text>
                <b-card-text v-if="product.arrivalDepositZones.length > 0" class="mb-2">
                  <span class="font-weight-bold">{{ $t('trip.shop.deposit_zones') }}</span>
                  <ul><li v-for="zone in product.arrivalDepositZones" :key="zone.id">{{ zone.address }}</li></ul>
                </b-card-text>
                <b-card-text class="mb-1">
                  <span class="font-italic">{{ $t('trip.shop.note.title') }} :</span>
                  <p>{{ $t('trip.shop.note.content') }}</p>
                </b-card-text>
              </div>
              <div class="d-flex justify-content-end align-items-center">
                <h4 class="item-price m-0">
                  <span v-if="!product.minPrice" class="h5">
                    {{ $t('trip.shop.price_on_quote') }}
                  </span>
                  <span v-else> {{ product.minPrice.displayPriceIncludingTaxes | priceFormat }} {{ $t('trip.shop.cart.incl_taxes') }}</span>
                </h4>
                <b-button :disabled="!eventDetail || !product" class="ml-1 mt-0 min-30" type="submit" size="md" @click="isHelicopterSidebarActive = true">
                  <span class="align-middle">{{ regularFlight ? $t('trip.shop.request_a_quotation') : $t('trip.shop.request_a_custom_quotation') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-overlay>
      </b-card-body>
    </b-card>
    <b-card v-if="product && product.mainVariant.description" no-body>
      <b-card-body>
        <b-row class="my-2">
          <b-col>
            <b-card-text class="mb-1">
              <h2 class="font-weight-bold">{{ $t('trip.shop.description') }} :</h2>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <p v-html="this.$options.filters.trans(product.mainVariant.description)"></p>
            </b-card-text>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- SIDEBAR TRIP INFO -->
    <sidebar-helicopter v-if="eventDetail && product" :event-detail="eventDetail" :regular-flight="regularFlight" :product="product" :is-helicopter-sidebar-active.sync="isHelicopterSidebarActive" />
  </section>
</template>

<script>
import {
  fetchProductOnQuoteFlightServiceDetailed,
  fetchProductRegularFlightServiceDetailed,
} from '@/request/globalApi/requests/tripStepEventRequests'
import SidebarHelicopter from '@/views/shop/shop-details/sidebar/SidebarHelicopter.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ShopHelicopterDetails',
  components: { SidebarHelicopter },
  data() {
    return {
      isHelicopterSidebarActive: false,
      regularFlight: true,
      productLoading: false,
      product: undefined,
      eventDetail: null,
    }
  },
  computed: {
    ...mapState('trip', ['trip']),
    userLang() {
      return this.$store.state.appConfig.lang
    },
  },
  mounted() {
    this.$route.params.type === 'regular' ? this.regularFlight = true : this.regularFlight = false
    this.APIFetchProduct()
    this.fetchTripInfos()
  },
  methods: {
    ...mapActions('trip', ['fetchTrip']),

    async fetchTripInfos() {
      const eventId = Number(this.$route.params.event_id)
      const tripId = Number(this.$route.params.trip_id)
      const trip = await this.fetchTrip({ tripId })
      if (trip) {
        this.eventDetail = this.trip.tripSteps.find(step => (step.transferArrival && step.transferArrival.id === eventId) || (step.transferDeparture && step.transferDeparture.id === eventId))
      }
    },
    APIFetchProduct() {
      this.productLoading = true
      const tripStepEventId = this.$route.params.event_id
      const requiredOrganizationId = this.$route.params.required_organization_id
      const destinationId = this.$route.params.destination_id
      const variantId = this.$route.params.destination_id
      if (this.regularFlight) {
        fetchProductRegularFlightServiceDetailed(tripStepEventId, requiredOrganizationId, destinationId)
          .then(r => { this.product = r.data })
          .catch(() => { this.product = null })
          .finally(() => { this.productLoading = false })
      } else {
        fetchProductOnQuoteFlightServiceDetailed(tripStepEventId, requiredOrganizationId, variantId)
          .then(r => { this.product = r.data })
          .catch(() => { this.product = null })
          .finally(() => { this.productLoading = false })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
.min-30 {
  min-width: 30%;
}
</style>
